.msgHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  gap: 10px;
  h3 {
    width: 100%;
  }
}
.msgInnerContent {
  flex: 1;
  width: 100%;
  margin: 10px;
}
.msgRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.warningMsg {
  display: flex;
  flex-direction: column;
  max-width: 780px;
  min-height: 100px;
  background-color: var(--color-red-400);
  color: var(--color-white);
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 8px;
  ul {
    padding-inline-start: var(--spacing-sm);
  }
  p,
  ul {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .errorIcon {
    width: 22px;
    height: 22px;
    color: var(--color-red);
  }
}

.successMsg {
  max-width: 780px;
  min-height: 100px;
  background-color: var(--color-green-400);
  color: var(--color-white);
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 8px;
  p,
  ul {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .successIcon {
    width: 22px;
    height: 22px;
    color: var(--color-green);
  }
}

.infoMsg {
  display: flex;
  max-width: 780px;
  background-color: var(--color-blue-400);
  color: var(--color-white);
  border-radius: 8px;
  p {
    font-size: 14px;
    width: 100%;
  }
  .infoIcon {
    width: 18px;
    height: 18px;
    color: var(--color-blue);
  }
  /* Style code related to RESERVED_NAMES feature
  .msgIcon {
    filter: brightness(0) saturate(100%) invert(42%) sepia(37%) saturate(4830%)
      hue-rotate(215deg) brightness(105%) contrast(99%);
  }
  */
}

.closeButton {
  width: 26px;
  height: 26px;
  padding: 0;

  .xIcon {
    width: 26px;
    height: 26px;
    color: 535250;
  }
}

.viewGuideButton {
  max-width: 240px;
  min-width: 150px;
  height: 28px;
  margin-top: auto;
  margin-left: auto;
}

.errorLink {
  color: var(--color-white);
  font-weight: bold;
  margin-left: 0.5rem;
}
