@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  height: fit-content;
  padding: var(--spacing-sm);

  @media screen and (min-width: $breakpoint-md) {
    padding: var(--spacing-md) var(--spacing-lg);
  }
}

.text {
  color: var(--color-grey-300);
  margin-bottom: 4px;
}

.form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  width: 100%;
  max-width: 514px;
  margin-top: 24px;
  margin-bottom: 6px;
}

.formHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.formTitle {
  color: var(--color-grey-200);
}

.button {
  outline: none;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  height: 28px;
  color: var(--color-white);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;

  img {
    margin-right: 4px;
  }
}

.checkRolesButton {
  min-width: 168px;
  height: 40px;
  white-space: nowrap;
  margin-bottom: 12px;
  align-self: flex-start;
  @media screen and (min-width: $breakpoint-md) {
    align-self: flex-end;
    margin-bottom: 6px;
  }
}

.resolveRolesButtonContainer {
  display: flex;
  justify-content: center;
  max-width: 440px;
}

.resolveRolesButton {
  width: 132px;
  height: 40px;
  margin-top: 25px;
}

.checkBotButtonLoading {
  width: 240px;
  min-width: 240px;
  margin-right: 20px;
  height: 40px;
  margin-bottom: auto;
  background-color: white;
  color: var(--color-black);
}

.checkBotButtonLoading:focus {
  background-color: rgba(255, 255, 255, 1);
}

.cancel {
  margin-left: auto;
}

.searcharea {
  flex: 1;
}

.searchareaWithButtonContainer {
  display: flex;
  gap: 6px;
  flex-direction: column;
  @media screen and (min-width: $breakpoint-md) {
    flex-direction: row;
  }
}

.searchInput {
  width: 100%;
  max-width: 440px;
  margin-bottom: 6px;
}

.textarea {
  max-width: 440px;
  background: var(--color-grey-500);
  padding: 9px 12px;
  margin: 10px 0;
  border-radius: 8px;
  resize: none;
  border: 1px;
}

.cardRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}

.cardBox {
  flex: 1;
  display: flex;
  justify-content: left;
}
.cardBox:last-child {
  justify-content: right;
}
.cardKey {
  color: var(--color-grey-300);
}

.cardValue {
  color: var(--color-yellow);
}

.info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin: 5px 0;
}
.infoIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.copyIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: var(--color-grey-100);
}
.warningIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  filter: invert(45%) sepia(58%) saturate(3106%) hue-rotate(333deg)
    brightness(96%) contrast(111%);
}
.infoText {
  color: var(--color-grey-200);
}
.filtersRow {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.rolesContent {
  margin-top: 6px;
  max-width: 440px;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (min-width: $breakpoint-md) {
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey-600) rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
      width: 6px;
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: var(--color-grey-600);
    }
  }
}

.roleCell {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2px;

  &:nth-child(2n) {
    .roleBody {
      background: #151414;
    }
  }
}

.roleBody {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-radius: 4px;
  width: 100%;
  padding: 4px 8px;

  @media screen and (min-width: $breakpoint-md) {
    width: calc(100% - 6px);
  }
}

.roleBodyWarning {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  background: var(--color-red-400);
  border-radius: 4px;
  width: 100%;
  padding: 4px 8px;
  .roleValue {
    color: var(--color-white);
  }
  .roleValueWarning {
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row nowrap;
  }

  @media screen and (min-width: $breakpoint-md) {
    width: calc(100% - 6px);
  }
}

.roleBodyRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  width: 100%;
  padding: 4px 8px;

  @media screen and (min-width: $breakpoint-md) {
    width: calc(100% - 6px);
  }
}
.roleValue {
  color: var(--color-white);
}
.formLoading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: rgba(11, 6, 4, 0.7);
}
.errorMsg {
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin: 0px 0px 10px 10px;
  white-space: pre-line;
  padding: 0px;
  .errorMsgText {
    color: var(--color-error);
    font-size: 12px;
  }
}
.input {
  height: 40px;
}

.errorMsgIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  filter: invert(45%) sepia(58%) saturate(3106%) hue-rotate(333deg)
    brightness(96%) contrast(111%);
}
.errorMsgText {
  color: var(--color-error-alt);
}
