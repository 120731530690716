@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.title {
  margin-bottom: 4px;
  color: var(--color-white);
}
