@import '../../styles/typography.module.scss';
@import '../../styles/variables.module.scss';

.header {
  text-align: center;
  color: var(--color-white);
  font-weight: 600;
}

.infoMsg {
  padding: 20px;
  text-align: center;
  line-height: 24px;
  p {
    color: var(--color-white);
    font-weight: bold;
    font-size: 16px;
  }

  span {
    font-weight: normal;
    font-size: 16px;
  }
}

.btn {
  margin-bottom: 16px;
  min-width: 288px;
  a {
    font-weight: 600;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.discordBtn {
  margin-bottom: 24px;
  min-width: 240px;
  background-color: #5a64ea;
  color: #ffffff;
  border: 0;
  img {
    margin-right: 16px;
  }
}
.discordBtn:focus,
.discordBtn:hover {
  background-color: #5a64ea;
}

.discordBtn:active {
  background-color: #5a64ea;
}

.telegramBtn {
  margin-bottom: 24px;
  min-width: 240px;
  background-color: #27a6e5;
  color: #ffffff;
  border: 0;
  img {
    margin-right: 16px;
  }
}
.telegramBtn:focus,
.telegramBtn:hover {
  background-color: #27a6e5;
}

.telegramBtn:active {
  background-color: #27a6e5;
}

.mascotLogo {
  width: 163px;
  z-index: 1;
  @media screen and (min-width: $breakpoint-md) {
    width: 261px;
  }
}

.section {
  padding: 20px;
  display: flex;
  flex-direction: row;
}
