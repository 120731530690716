@import '../../styles/typography.module.scss';
@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.amountRow {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-flow: column wrap;
  margin: 32px 0 20px;
  gap: var(--spacing-md);

  @media screen and (min-width: $breakpoint-lg) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
}

.amountInput {
  @media screen and (min-width: $breakpoint-lg) {
    width: calc(50% - 8px) !important;
  }
}

.addressRow {
  margin-top: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  flex-flow: row nowrap;
}

.amountInput ::placeholder {
  font-size: 14px;
  line-height: 24px;
  color: var(--font-secondary-color);
}

.captionText {
  color: var(--font-secondary-color);
  margin-top: 8px;
  margin-bottom: 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  .info {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.customSelect {
  .Select-value-label,
  .Select-option {
    white-space: pre-wrap;
  }
}

.metadataRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.metadataText {
  color: var(--color-grey-200);
}

.metadataButton {
  div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .plus {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: var(--color-yellow);
  }
  .edit {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: var(--color-yellow);
  }
  color: var(--color-white);
}

.metadataCaption {
  margin-bottom: 14px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-white);

  .errorMsgIcon {
    color: var(--color-yellow);
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.metadataTraitId {
  color: var(--color-grey-300);
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.metadataContent {
  margin-top: 6px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.metadataCell {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2px;

  &:nth-child(2n) {
    .metadataBody {
      background: #151414;
    }
  }
}

.metadataBody {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  background: transparent;
  border-radius: 4px;
  width: calc(100% - 20px);
  padding: 4px 8px;
}

.metadataValue {
  color: var(--color-grey-300);
}

.metadataBodyButton {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  padding: 0;

  .close {
    color: var(--color-white);
    width: 16px;
    height: 16px;
  }
}

.tabsList {
  display: flex;
  margin-top: 32px;
  border-radius: 8px;
}

.tabsBorder {
  border: 1px solid var(--color-yellow);
  justify-content: space-around;
}

.tabsTrigger {
  color: var(--color-grey-200);
  padding: 10px;
  flex: 1 0 auto;
  border-radius: 8px;
  transition: background-color ease-in 150ms;
  &[data-state='active'] {
    background-color: var(--color-yellow-600);
  }
}

.singleTab {
  justify-self: flex-start;
  padding: 10px 0;
  color: var(--color-yellow);
}

.metadataSection {
  margin: 14px 0;
}

.infoTable {
  margin-top: 8px;
  & th {
    @extend .caption2;
    text-align: left;
  }
  & td {
    @extend .caption1;
    color: var(--color-grey-200);
  }
}

.errorCaption {
  @extend .caption1;
  color: var(--color-error-alt);
}

.roninCustomAddrOr {
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  flex-flow: row nowrap;
  color: var(--color-grey-200);
  margin-bottom: -16px;
}
