@import './variables.module.scss';
@import './typography.module.scss';

// List pages common css files (GuestPassConfig, MarketplacePage, RoleCompositionPage, SnapshotConfiguration, TpcConfig)
.headerRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: var(--spacing-sm);
}

.headerTitle {
  color: var(--color-white);
  margin-bottom: 10px;
}

.headerButton {
  min-width: 115px;

  .plus {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.operator {
  color: var(--operator-color);
  margin-top: 10px;
  font-weight: 700;
  text-align: center;
}

.andOperator {
  color: var(--color-yellow);
  margin-bottom: 12px;
  font-weight: 700;
  text-align: center;
}

.empty {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  @media screen and (min-width: $breakpoint-md) {
    margin: 150px 0 auto;
  }
}

.emptyImage {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 304px;
  margin-bottom: 32px;

  @media screen and (min-width: $breakpoint-md) {
    position: unset;
    transform: unset;
  }
}

.emptyButton {
  z-index: 1;
  .plus {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  @media screen and (min-width: $breakpoint-md) {
    z-index: unset;
  }
}

.emptyText {
  margin: var(--spacing-lg) 0 var(--spacing-md) 0;
  color: var(--color-white);
  text-align: center;
  z-index: 1;

  @media screen and (min-width: $breakpoint-sm) {
    margin: calc(var(--spacing-lg) * 2) 0 var(--spacing-md) 0;
  }

  @media screen and (min-width: $breakpoint-md) {
    margin: 0 0 var(--spacing-md) 0;
    z-index: unset;
  }
}

.rolesRow {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  gap: var(--spacing-md);
}

.selectRoleButton {
  width: 100%;
  min-width: 240px;
  height: 40px;
  margin-bottom: auto;

  .plus {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  @media screen and (min-width: ($breakpoint-md - 240px)) {
    width: 240px;
  }
}

.roleWrapper {
  width: 100%;
  min-width: 240px;
  padding-bottom: 32px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: ($breakpoint-md - 240px)) {
    width: 240px;
  }
}

.pageLoading {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  scroll-behavior: contain;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  background: rgba(11, 6, 4, 0.7);
}

.roleHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  padding: 10px 4px 10px 12px;
  color: var(--color-black);
  background-color: var(--color-grey-500);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin: 0px 8px 2px 0px;
  }
}

.roleName {
  color: var(--color-white);
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.roleAddButton {
  width: 36px;
  height: 36px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: 0;
  margin-right: 4px;
  margin-left: auto;
  color: var(--color-white);
  .plus {
    width: 16px;
    height: 16px;
  }
}

.roleEmpty {
  width: 100%;
  height: 56px;
  color: var(--color-white);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  .plus {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    color: var(--color-yellow);
  }
}

.clearFiltersButton {
  width: 120px;
  min-width: 120px;
  border: 0;
  background-color: rgba(252, 133, 3, 0.2);
  height: 32px;
  margin-bottom: auto;
  border-radius: 8px;
  color: var(--color-white);
  font-weight: 600;
}

.filtersRow {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: var(--spacing-sm);

  @media screen and (min-width: $breakpoint-md) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
}

.filtersRowFilter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacing-sm);
  order: 2;
  span {
    margin-left: 5px;
    color: var(--color-yellow);
  }
  @media screen and (min-width: $breakpoint-md) {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    order: unset;
  }
}

.filters {
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-sm);
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filterButton {
  color: var(--color-white);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:focus-visible {
    outline: 1px solid var(--color-grey-300);
    border-radius: 4px;
  }
  &[data-state='open'] {
    svg {
      transform: rotate(180deg);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    order: unset;
  }
}

.shortButton {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  transition: all 0.1s linear;
  color: var(--color-yellow);
}

.reverse {
  img {
    transform: rotate(180deg);
  }
}

.roleForm {
  width: 100%;
  position: relative;
  max-width: 440px;
  margin-top: 32px;
  padding: 0 2px;
}

.addressInput {
  width: 100%;
}

.addressLink {
  width: 48px;
}

.addressLinkIcon {
  width: 48px;
}

.addressInput ::placeholder {
  font-size: 14px;
  line-height: 24px;
  color: var(--font-secondary-color);
}

.inputWrapper ::placeholder {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-grey-300);
}

.infoWarning {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-top: 7px;
}

.infoIconWarning {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 8px;
}

.inputNoLabel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  width: 100%;
}

.formRow {
  padding: 40px 0;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-sm);
}

.formReset {
  width: 100%;
  max-width: 120px;
  height: 40px;
}

.formSubmit {
  width: 100%;
  max-width: 308px;
  height: 40px;
}

.errorLabel {
  color: var(--color-error);
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.selectError {
  div[class*='control'] {
    border: 2px solid var(--color-error) !important;
  }
}

.inlineErrorMsgInfo {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-bottom: 8px;
  margin-top: 30px;
  margin-left: 8px;
  margin-right: 8px;
  color: var(--color-error);
}

.errorMsg {
  @extend .title5;
  background-color: var(--color-error);
  color: var(--color-grey-200);
  margin-bottom: 10px;
  padding: 10px;
  white-space: pre-line;
  word-wrap: break-word;
  border-radius: 8px;
}

.label {
  display: block;
  margin-bottom: 8px;
  margin-top: 30px;
  color: var(--color-grey-200);
}

.input {
  height: 48px;
}

.banner {
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  min-height: 40px;
  white-space: pre-line;
  width: 100%;
  border-radius: 8px;
  align-items: center;
  .infoIcon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
  .attentionIcon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    color: var(--color-black);
  }
}
.banner.warning {
  background-color: var(--color-yellow-400);
  p {
    color: var(--color-white);
  }
  .infoIcon {
    color: var(--color-yellow);
  }
}
.banner.error {
  background-color: var(--color-red-400);
  p {
    color: var(--color-white);
  }
  .infoIcon {
    color: var(--color-red);
  }
}

.title {
  color: var(--color-white);
  width: 100%;
  margin-bottom: 28px;
}

.cancel {
  width: 122px;
  height: 40px;
}

.submit {
  width: 122px;
  height: 40px;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  background-image: url('./../images/createTPCBG.svg');
  background-repeat: no-repeat;
  background-size: 100vw auto;
  background-position: 100% calc(100% + 100px);
  background-attachment: fixed;

  @media screen and (min-width: $breakpoint-md) {
    background-position: 100% calc(100% + 190px);
  }
}
