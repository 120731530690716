.root {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 400;
}

.hidden {
  transform: translateX(-100%);
}
