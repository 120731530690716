@import '../../styles/variables.module.scss';

.cardRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.cardBox {
  flex: 1;
  display: flex;
  justify-content: left;
}

.cardBox:last-child {
  justify-content: right;
}

.cardKey {
  color: var(--color-grey-100);
  font-weight: 200;
}

.cardValue {
  color: var(--color-yellow);
}

.cardLink {
  color: var(--color-yellow);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 30ch;
  @media screen and (min-width: $breakpoint-md) {
    width: 40ch;
  }
}

.copyIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: #aaa8a7;
}
