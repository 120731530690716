.tpcDetail {
  width: 320px;
  padding: 12px 14px;
  background: var(--color-grey-800);
  margin-bottom: 12px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  &:before {
    content: ' ';
    width: 4px;
    display: block;
    height: 100px;
    max-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--color-yellow);
  }
}

.tpcDetailRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  color: var(--color-white);
}

.infoTopAlt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-top: 6px;
  margin-bottom: 6px;
  .infoIcon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    pointer-events: none;
  }
  .infoText {
    color: var(--color-grey-200);
    font-weight: 600;
    font-size: 12px;
  }
  span {
    color: var(--color-grey-500);
    font-size: 12px;
    margin-left: 8px;
  }
}
