.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  background-image: url('../../images/createTPCBG.svg');
  background-repeat: no-repeat;
  background-size: auto 100vh;
  background-position: 100% calc(100% + 190px);
  background-attachment: fixed;

  img {
    width: 91px;
    margin-bottom: 32px;
  }

  h2 {
    text-align: center;
    color: var(--color-white);
    margin-bottom: 24px;
  }

  button {
    width: 160px;
  }
}
