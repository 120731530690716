@import '../../../../styles/variables.module.scss';
@import '../../../../styles/common.module.scss';

.listContainer {
  width: 100%;
  height: 240px;
  overflow: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.heading {
  color: var(--color-black);
  font-family: var(--font-secondary);
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 20px;
  word-wrap: break-word;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
}

.contextText {
  color: #7d8087;
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  width: 100%;
  word-wrap: break-word;
}

.heading2 {
  color: var(--color-black);
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 40px;
  word-wrap: break-word;
}

.contextText2 {
  color: #7d8087;
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 100%;
  word-wrap: break-word;

  b {
    color: var(--color-black);
    font-weight: 1000;
  }
}

.tgrButton {
  margin-top: 20px;
  width: 100%;
}

.permissionGuide {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 240px;
  height: 380px;
}

.chatSetting {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 220px;
  height: 150px;
  border-radius: 10px;
}

.addMember {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 150px;
  border-radius: 10px;
}

.learnMoreLink {
  color: #0695e7;
  text-decoration: underline;
}

.buttonGroup {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  button {
    color: #0b0604;
  }
}

.telegramBtn {
  margin-top: 24px;
  padding: 10px;
  width: 240px;
  height: 60px;
  background-color: #27a6e5;
  color: #ffffff;
  border: 0;

  img {
    margin-right: 16px;
  }
}

.telegramBtn:focus,
.telegramBtn:hover {
  background-color: #27a6e5;
}

.telegramBtn:active {
  background-color: #27a6e5;
}
