@import '../../styles/variables.module.scss';

.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(11, 6, 4, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.container {
  width: calc(100vw - calc(var(--spacing-sm) * 2));
  max-width: 500px;
  min-height: 216px;
  padding: var(--spacing-sm);
  background: var(--color-grey-800);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  border-radius: 12px;
  margin: var(--spacing-sm);

  @media screen and (min-width: $breakpoint-md) {
    width: calc(100vw - calc(var(--spacing-md) * 2));
    padding: var(--spacing-md);
  }
}
