@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  padding: var(--spacing-sm);

  @media screen and (min-width: $breakpoint-md) {
    padding: var(--spacing-md) var(--spacing-lg);
  }
}

.flex {
  flex: 1;
}

.row {
  width: 100%;
  max-width: 514px;
  flex-flow: row wrap;
}

.rowReverse {
  width: 100%;
  max-width: 514px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
}

.description {
  width: 100%;
}

.titleLabel {
  white-space: nowrap;
}

.titleRow {
  display: flex;
  width: 100%;
  flex-flow: row;
  align-items: center;
  gap: 1.75rem;
  flex-basis: 320px;
  justify-content: space-between;
}

.titleRowBody {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.subtitle {
  width: fit-content;
  color: var(--color-white);
}

.text {
  color: var(--color-grey);
  margin-bottom: 4px;
  max-width: 460px;
}

.separator {
  margin-top: 20px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 514px;
  height: 1px;
  background: var(--color-grey-600);
}

.form {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 514px;
  margin-top: 24px;
  margin-bottom: 6px;
}

.formHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.formTitle {
  color: var(--color-grey-200);
}

.button {
  outline: none;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  height: 28px;
  color: var(--color-white);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;

  img {
    margin-right: 4px;
  }
}

.checkBotButton {
  width: 240px;
  min-width: 240px;
  height: 40px;
  margin-bottom: auto;
}

.checkBotButtonLoading {
  width: 240px;
  min-width: 240px;
  height: 40px;
  margin-bottom: auto;
  background-color: white;
  color: var(--color-black);
}

.checkBotButtonLoading:focus {
  background-color: rgba(255, 255, 255, 1);
}

.textarea {
  width: 100%;
  background: var(--color-black);
  color: var(--color-white);
  padding: 9px 12px;
  border-radius: 8px;
  margin-bottom: 6px;
  resize: none;
  border: 1px solid var(--disabled);
}

.textarea::placeholder {
  color: var(--color-grey);
}

.info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.infoTextColor {
  color: var(--color-grey);
}

.linkarea {
  max-width: 500px;
  width: 500px;
  padding: 9px 12px;
  border-radius: 8px;
  margin-bottom: 6px;
  resize: none;
  border: 1px;
}

.modal {
  .title {
    text-align: center;
    margin-bottom: 18px;
  }

  .text {
    color: var(--color-white);
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
  }

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
  }
}

.badge {
  margin-left: 1.5rem;
}

.resetButton {
  width: 120px;
}

.saveButton {
  width: 220px;
}

.selectContainer {
  margin: 0.75rem 0 1.5rem 0;
  width: 100%;
}

.formButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
}

.customMessageTitle {
  margin-top: 0.5rem;
}

.remainingCharactersText {
  float: right;
  position: relative;
  top: -5px;
}

.gap2 {
  gap: 2rem;
}

.checkboxWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  height: 1rem;
  margin-top: 0.75rem;
}

.balanceCheckButton {
  margin-top: 0.75rem;
}

.sectionPadding {
  padding: 10px 0;
}
