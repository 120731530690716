@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  justify-content: flex-start;
}

.title {
  text-align: center;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding-top: 32px;
  margin-top: auto;
}
