@import '../../../styles/variables.module.scss';

.textarea {
  width: 100%;
  background: var(--color-black);
  color: var(--color-white);
  padding: 9px 12px;
  border-radius: 8px;
  margin-bottom: 6px;
  resize: none;
  border: 1px solid var(--color-yellow-400);
}

.textarea::placeholder {
  color: var(--color-grey);
}

.remainingCharactersContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.infoTextColor {
  color: var(--color-grey);
}

.titleRow {
  display: flex;
  width: 100%;
  flex-flow: row;
  align-items: center;
  gap: 1.75rem;
  flex-basis: 320px;
  justify-content: space-between;
}

.titleLabel {
  white-space: nowrap;
}

.subtitle {
  width: fit-content;
  color: var(--color-white);
}

.row {
  width: 100%;
  max-width: 514px;
  flex-flow: row wrap;
}

.gap2 {
  gap: 2rem;
}

.flex {
  flex: 1;
  width: 360px;
}

.customMessageTitle {
  margin-top: 0.5rem;
  display: flex;
  justify-content: start;
  gap: 4px;
}

.infoTopAlt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-top: 6px;
  margin-bottom: 6px;
  .infoIcon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    pointer-events: none;
  }
  .infoText {
    color: var(--color-grey-200);
    font-weight: 600;
    font-size: 12px;
  }
  span {
    color: var(--color-grey-500);
    font-size: 12px;
    margin-left: 8px;
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.optout {
  margin: 10px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  justify-content: end;
  align-items: center;
}

.pageLoading {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  scroll-behavior: contain;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  background: rgba(11, 6, 4, 0.7);
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.checkboxWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  height: 1rem;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
}

.formButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
}

.resetButton {
  width: 200px;
}

.saveButton {
  width: 180px;
}

.publishButton {
  width: 120px;
}

.selectedColor {
  width: 16px;
  height: 16px;
  background-color: red;
  border-radius: 3px;
  border: 2px solid #ccc;
}

.dropdownContent {
  margin: 10px 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  z-index: 999;
}

.container {
  display: flex;
  width: fit-content;
  max-width: 500px;
  padding: 30px;
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

.text {
  color: var(--color-white);
  overflow-wrap: anywhere;
}

.priceText {
  color: var(--color-white);
}

.priceTextError {
  color: var(--color-error);
}

.priceValue {
  color: var(--color-yellow);
  width: 100%;
  font-size: 16px;
  text-align: right;
}

.infoText {
  width: 100%;
  color: var(--color-white);
  text-align: center;
  padding: 14px 0;
  margin-bottom: 25px;
}

.errorText {
  width: 100%;
  color: var(--color-error);
  text-align: center;
  margin-bottom: 25px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: 16px;
}

.contentRow {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.buttonsRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-flow: row nowrap;
  margin-top: auto;
}

.buttonFixWidth {
  width: 130px;
}

.uninstallText {
  white-space: inherit;
  max-width: 260px;
  margin-bottom: 35px;
  text-align: center;
}

.statusText {
  color: var(--color-green);
}

.metaDataContainer {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
  align-items: flex-start;

  a {
    text-decoration: underline;
  }

  p {
    overflow-wrap: anywhere;
  }
}

.metadataKey {
  color: var(--color-grey-200);
}

.secondaryHeader {
  margin-top: 20px;
}
