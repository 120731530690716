.contentWrapper {
  width: 100%;
  max-width: 514px;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  transition: 0.25s;
  overflow: hidden;
}

.contentHeightWrapper {
  width: 100%;
}

.titleWrapper {
  width: 100%;
  max-width: 514px;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row;
}

.arrow {
  width: 18px;
  height: 18px;
  transition: all 0.1s linear;
  color: var(--color-white);
}

.collapsed {
  transform: rotate(180deg);
}
