.h1 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
}

.h2 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
}

.title1 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.title2 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.title3 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.title4 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.title5 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.title6 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}

.body1 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.body2 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}

.caption1 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.caption2 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}

.paragraph {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.button1 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.button2 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.button3 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
.customRadio {
  display: block;

  > input {
    display: none;
  }

  > i {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
    vertical-align: middle;
    border: 2px solid var(--color-grey);
    transition: 0.25s;
  }

  > input:checked + i {
    border: 4px solid var(--color-yellow);
    background-color: var(--color-white);
  }

  &:hover {
    cursor: pointer;
  }
}
