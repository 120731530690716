@import '../../styles/variables.module.scss';

.navigation {
  padding: 24px 0 12px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  flex: 1;
  width: 100%;
}

.placeholderInput {
  width: 170px;
  height: 24px;
  background: var(--color-grey);
  border-radius: 4px;
  margin: 0 12px 26px;
}

.communityName {
  color: var(--color-white);
  padding: 0 12px;
}

.communitySubName {
  color: var(--color-white);
  margin-bottom: 20px;
  display: flex;
  font-size: 14px;
  padding: 3px 12px;
  align-items: center;
  justify-content: center;

  .iconLogo {
    width: 16px;
    height: 16px;
    margin-right: 7px;
  }

  .iconLogoT {
    width: 16px;
    height: 16px;
    margin-right: 7px;
  }

  .subtitle {
    text-transform: capitalize;
  }
}

.menuSection {
  margin-top: 10px;
}

.title {
  margin: 8px 0;
  color: var(--color-grey);
  padding: 0 12px;
}

.link {
  text-decoration: none;
  color: var(--color-white);
  height: 100%;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.linkWrapper {
  height: 36px;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.linkWrapperSpaceBetween {
  @extend .linkWrapper;
  justify-content: space-between;
  padding-right: 12px;
}

.mobileWalletWrapper {
  width: 100%;
  margin: 10px 0;
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: none;
  }
}

.active {
  background: var(--color-grey-500);
}

.disabled {
  color: #414141;
}

.disabledIcon {
  opacity: 0.5;
}

.supportContainer {
  padding: 24px 0 12px 0;
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-flow: column nowrap;
  width: 100%;
}

.support {
  width: 80%;
  max-width: 192px;
  margin-top: 20px;

  img {
    height: 16px;
    margin-right: 8px;
  }
}

.premiumSupport {
  padding: 0;
  margin-top: 20px;
  width: 80%;
  max-width: 192px;

  img {
    height: 16px;
    margin-right: 8px;
  }
}

.donate {
  background-color: #81c784;
  border-color: #81c784;
  margin-top: 20px;
  width: 80%;
  max-width: 192px;

  img {
    height: 16px;
    margin-right: 8px;
  }

  &:hover {
    background-color: #6faa6e;
    border-color: #6faa6e;
  }

  &:active {
    background-color: #5e9460;
    border-color: #5e9460;
  }

  &:focus {
    background-color: #6faa6e;
    border-color: #6faa6e;
  }
}

.control {
  //position: sticky;
  //left: 0;
  //bottom: 0;
  width: 100%;
  height: 48px;
  background: var(--color-grey-500);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.userIcon {
  border-radius: 100%;
  background: var(--color-grey-600);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

.userName {
  margin-left: 10px;
  color: var(--color-white);
}

.leave {
  background: transparent;
  cursor: pointer;
  outline: none;
  border: 0;
  margin-left: auto;

  .exit {
    color: #aaa8a7;
    width: 24px;
    height: 24px;
  }
}

.spinnerWrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.badge {
  margin-left: 6px;
}

.releaseNotesWrapper {
  justify-content: center;
}

.releaseNotesButton {
  font-size: 0.75rem;
  margin-top: 0.5rem;
  text-align: center;
  text-decoration: underline;
  color: var(--color-grey);
}
