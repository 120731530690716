.root {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background: var(--color-black);
}

.container {
  padding-top: 128px;
  padding-bottom: var(--spacing-md);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
  z-index: 2;
}

.bg {
  position: absolute;
  right: 0;
  top: 0;
  width: 47%;
  z-index: 0;
}

.logo {
  position: absolute;
  left: 50%;
  top: 64px;
  transform: translateX(-50%);
  width: 217px;
  z-index: 1;
}
