@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  width: 100%;
  padding: 0 var(--spacing-sm);
  position: relative;
  min-height: calc(100vh - var(--top-bar-height));

  @media screen and (min-width: $breakpoint-md) {
    min-height: 100vh;
    padding: 0 var(--spacing-lg);
    width: calc(100% - 240px);
  }
}

.bg {
  bottom: 0;
  right: 0;
  position: absolute;
  width: 385px;
  pointer-events: none;
}

.head {
  position: sticky;
  top: 0;
  padding: var(--spacing-sm) 0;
  background: var(--color-black);
  z-index: 1;
}

.backButton {
  padding: 0;
  margin: 8px auto 28px 0;
  height: 24px;
  color: var(--color-yellow);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .arrow {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    color: var(--color-yellow);
  }
}

.headRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.headButton {
  margin-left: auto;
  margin-right: 8px;
  border: 1px solid var(--color-error);
  color: var(--color-white);
  font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    background: rgba(195, 30, 51, 0.2);
  }
}

.list {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.cardWrapper {
  width: calc(50% - 12px);
  margin-bottom: 12px;
  margin-top: 0;
}

.aside {
  position: sticky;
  top: 0;
  display: none;
  padding: 20px 16px;
  width: 240px;
  height: 100vh;
  background: var(--color-grey-600);

  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
}

.asideTitle {
  color: var(--color-white);
  margin-bottom: 16px;
}

.tpcItem {
  width: 100%;
  padding: 12px 14px;
  background: var(--color-black);
  margin-bottom: 12px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &:before {
    content: ' ';
    width: 4px;
    display: block;
    height: 100px;
    max-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--color-yellow);
  }
}

.tpcItemRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.tpcItemKey {
  color: var(--color-white);
}

.tpcItemValue {
  color: var(--color-yellow);
}

.formWrapper {
  position: relative;
}

.formLoading {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  background: rgba(11, 6, 4, 0.7);
}
