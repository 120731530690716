@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.title {
  text-align: center;
  margin-bottom: 18px;
}

.text {
  color: var(--color-white);
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}
