@import '../../styles/variables.module.scss';

.root {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-md) {
    flex-flow: row nowrap;
  }
}
