@import '../../styles/variables.module.scss';

.modalRoot {
  height: auto;
  min-height: 100%;
  position: absolute;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 790px;
  background-color: #a0cdff;
  align-items: center;
  padding: 0;

  @media screen and (min-width: $breakpoint-md) {
    flex-direction: row;
  }
}

.modalScroll {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: white;
  padding: 1.875rem;
  border-radius: 12px;
  box-shadow: 49px 55px 30px rgba(0, 0, 0, 0.01),
    28px 31px 25px rgba(0, 0, 0, 0.03), 12px 14px 19px rgba(0, 0, 0, 0.04),
    3px 3px 10px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.title {
  padding: 2.624rem 0;
}

.checkboxWrapper {
  display: flex;
  gap: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0 0.75rem 0;
}

.marginBottom {
  margin-bottom: 1rem;
}

.underline {
  text-decoration: underline;
}

.maskot {
  width: 350px;
}

.button {
  padding: 0.25rem 1.5rem;
  color: white;
  background-color: var(--color-purple);
  border-radius: 8px;
}
