.checkBoxContainer {
  display: flex;
}

.checkBox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 14px;
  height: 14px;
  background-clip: content-box;
  background-color: transparent;
  border: 1px solid #7b7977;
  border-radius: 2px;

  &:checked {
    background-color: var(--color-yellow);
  }

  &:focus {
    outline: none !important;
  }
}

.inputNormal {
  color: #ffffff;
  background-color: transparent;
  border: 0px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  width: 100%;
}

.inputEdit {
  background-color: #7b7977;
  border: 0px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  width: 100%;
}
