@import '../../styles/variables.module.scss';

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.content {
  width: 100%;
  padding: 0 var(--spacing-sm);
  @media screen and (min-width: $breakpoint-md) {
    width: calc(100% - 240px);
    padding: 0 var(--spacing-lg);
  }
}

.contentFull {
  width: 100%;
  padding: 0 var(--spacing-sm);

  @media screen and (min-width: $breakpoint-md) {
    padding: 0 var(--spacing-lg);
  }
}

.head {
  position: sticky;
  top: 0;
  background-color: var(--color-black);
  padding: var(--spacing-sm) 0;
  z-index: 1;
}

.backButton {
  padding: 0;
  margin: 8px auto 28px 0;
  height: 24px;
  color: var(--color-yellow);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .arrow {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }
}

.headRow {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-sm);
}

.headRowActions {
  margin-left: auto;
}

.headButton {
  margin-left: auto;
  margin-right: 8px;

  .plus {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.headDropdown {
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    background: var(--color-grey-600);
  }
}

.headDropdownContent {
  top: 7px !important;
}

.headCaption {
  margin-top: 8px;
  font-weight: normal;
}

.list {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.cardWrapper {
  width: 100%;
  margin-bottom: 12px;
  margin-top: 0;

  @media screen and (min-width: $breakpoint-lg) {
    width: calc(50% - 12px);
  }
}

.aside {
  position: sticky;
  top: 0;
  display: none;
  padding: 20px 16px;
  width: 240px;
  height: 100vh;
  background: var(--color-grey-600);

  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
}

.asideTitle {
  color: var(--color-white);
  margin-bottom: 16px;
}

.empty {
  width: 100%;
  margin: 150px 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

.emptyImage {
  width: 304px;
  margin-bottom: 32px;
}

.emptyText {
  margin-bottom: 24px;
  color: var(--color-white);
  text-align: center;
}

.emptyButton {
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    filter: brightness(0) saturate(100%) invert(3%) sepia(12%) saturate(1682%)
      hue-rotate(332deg) brightness(101%) contrast(101%);
  }
}

.roleButton {
  padding: 10px 0 10px 28px;
  width: 100%;
  height: 36px;
  margin-bottom: 8px;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  color: var(--custom-color);
  position: relative;

  &:before {
    content: ' ';
    background: var(--custom-color);
    width: 8px;
    height: 8px;
    position: absolute;
    left: 12px;
    top: 14px;
    border-radius: 1px;
  }
}

.active {
  background: var(--color-grey-500);
}
