.card {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 8px;
  margin-top: 8px;
  background: var(--color-black);
  border: 1px solid var(--color-grey-300);
  text-decoration: none;
}

.cardMain {
  background: var(--color-grey-500);
  width: 100%;
  padding: 8px 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cardMainActive {
  background: var(--color-yellow-500);
  width: 100%;
  padding: 8px 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cardAdditional {
  width: 100%;
  padding: 8px 12px;
  .cardValue {
    color: var(--color-white);
  }
}

.cardRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 32px;
}

.large {
  height: 36px;
}

.cardKey {
  text-align: left;
  font-weight: 200;
  color: var(--color-white);
  flex: 1;
}

.cardValue {
  color: var(--color-white);
  text-align: right;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
}

.cardButton {
  height: 24px;
  padding: 2px 24px;
  margin: 8px 0;
}

.iconButton {
  cursor: pointer;
  padding: 8px 10px;
  margin: 8px 0;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
