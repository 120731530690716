@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.root {
  display: flex;
  position: relative;
  max-width: 794px;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  background-color: var(--color-blue);
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    max-width: 418px;
    height: 100%;
    overflow: scroll;
  }
}

.container {
  position: relative;
  display: flex;
  padding: 28px;
  justify-content: flex-start;
  flex-direction: column;
  //width: 449px;
  //max-width: 449px;
  border-radius: 12px;
  box-shadow: 0px 8px 40px 0px rgba(27, 27, 27, 0.05);
  //height: 548px;
  background-color: var(--color-white);
  @media (max-width: 768px) {
    box-shadow: 0px 8px 40px 0px rgba(27, 27, 27, 0.05);
    padding: 14px;
  }
}

.imageContainer {
  //height: 416px;
  //width: 338px;

  img {
    height: 360px;
    left: 0;
    object-fit: cover;
    top: 0;
    width: 338px;
  }
}

.helpButton {
  //display: flex;
  //justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 28px;
  width: 88px;
  padding: 0;
  align-items: center;
  border: 2px solid var(--color-yellow);
  border-radius: 8px;
  background-color: var(--color-white);
  cursor: pointer;
  font-size: 12px;
  @media (max-width: 768px) {
    top: 10px;
    left: 16px;
  }
}

.closeButton {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 26px;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: var(--color-white);
  color: var(--color-blue);
  cursor: pointer;
  @media (max-width: 768px) {
    z-index: 200;
    background-color: var(--color-grey);
    color: var(--color-white);
  }
}
