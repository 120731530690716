@import '../../styles/typography.module.scss';
@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.roleForm {
  max-height: 720px;
  width: 100%;
  max-width: 100%;
  padding: 0 2px;
  overflow-y: auto;
  overscroll-behavior: contain;
  @media screen and (min-width: $breakpoint-md) {
    height: 750px;
    max-height: 750px;
    width: 750px;
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey-600) rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
      width: 6px;
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: var(--color-grey-600);
    }
  }
}

.label {
  margin-top: 0px;
}

.input {
  height: 48px;
}

.formTitle {
  color: var(--color-yellow);
}

.generalInput {
  width: 100%;
  padding: 0px;
}

.generalInput ::placeholder {
  font-size: 14px;
  line-height: 24px;
  color: var(--font-secondary-color);
}

.formRow {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: left;
  justify-content: flex-start;
  @media screen and (min-width: $breakpoint-md) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 20px;
  }
  gap: var(--spacing-sm);
}

.textarea {
  width: 100%;
  background: var(--color-black);
  color: var(--color-white);
  padding: 9px 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-top: 10px;
  resize: none;
  border: 1px solid var(--color-yellow-400);
  height: 8em;
  @media screen and (min-width: $breakpoint-md) {
    height: 11em;
    width: 54em;
  }
}

.textareaRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  @media screen and (min-width: $breakpoint-md) {
    width: 720px;
  }
}

.infoTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-bottom: 6px;
  padding-top: 10px;
  .infoIcon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
  .infoText {
    color: var(--color-grey-200);
    font-weight: 600;
    font-size: 12px;
  }
  span {
    color: var(--color-grey-500);
    font-size: 12px;
    margin-left: 8px;
  }
}

.infoTopAlt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-bottom: 6px;
  .infoIcon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    pointer-events: none;
  }
  .infoText {
    color: var(--color-grey-200);
    font-weight: 600;
    font-size: 12px;
  }
  span {
    color: var(--color-grey-500);
    font-size: 12px;
    margin-left: 8px;
  }
}

.infoBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  .infoIcon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.scrnCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column nowrap;
  margin: 20px;
  @media screen and (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    flex-flow: row nowrap;
  }
  .scrnIcon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  .scrnText {
    color: var(--color-grey-200);
    font-weight: 600;
    font-size: 16px;
  }
}

.scrnButton {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.buttonsRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  gap: 20px;
  padding-top: 32px;
  margin-top: auto;
}

.countGreen {
  color: var(--color-success);
}

.countRed {
  color: var(--color-error);
}

.chips {
  min-height: 36px;
  border-bottom: 2px solid blue;
  line-height: 1;
  font-size: 1em;
}

.chips-input {
  display: flex;
  width: 33%;
  min-height: 36px;
  margin-bottom: 5px;
  margin-left: 10px;
  border: 0;
  outline: none;
}

.chip {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  position: relative;
  background: #393837;
  border-radius: 8px;
  width: 110px;
  height: 33px;
}

.chip .chipValue {
  padding: 5px;
  padding-left: 15px;
  padding-right: 7.5px;
  color: var(--color-white);
  font-weight: bold;
  width: 110px;
  height: 33px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chip .chipDeleteButton {
  color: var(--color-white);
  border: 0;
  padding: 5px 10px;
  cursor: pointer;
  line-height: 0.5;
  font-weight: bold;
}

.checkBoxContainerTop {
  @media screen and (min-width: $breakpoint-md) {
    padding-top: 34px;
  }
}

.checkBoxContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  p {
    width: 180px;
  }
  div {
    display: flex;
    margin-left: 8px;
    margin-bottom: 5px;
    input {
      font-size: 12px;
      font-style: normal;
    }
  }
}

.appContactSection {
  ul {
    margin-top: 8px;
  }
  ul,
  a,
  span {
    color: var(--color-grey-200);
    font-weight: 200;
    font-size: 12px;
  }
  a {
    text-decoration: underline;
  }
}
