@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  height: 458px;
  justify-content: flex-start;
}

.search {
  width: 100%;
  position: relative;
  height: 32px;
  margin-bottom: 12px;

  input {
    width: 100%;
    height: 100%;
    padding-left: 36px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    color: var(--color-white);
    background: var(--color-grey-500);
    border: 1px solid var(--color-grey-500);
    &::placeholder {
      color: var(--color-grey);
    }
  }

  .searchIcon {
    position: absolute;
    left: 12px;
    top: 8px;
    width: 14px;
    height: 14px;
    color: #535250;
  }
}

.rolesWrapper {
  height: 216px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.rolesList {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overscroll-behavior: contain;

  @media screen and (min-width: $breakpoint-md) {
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey-600) rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
      width: 6px;
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: var(--color-grey-600);
    }
  }
}

.roleButton {
  background: transparent;
  outline: none;
  cursor: pointer;
  border: 0;
  padding-right: 15px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 100%;
  height: 36px;
  // color: var(--color-yellow);
}

.row {
  width: 100%;
  padding-top: 32px;
  margin-top: auto;
}

.title {
  text-align: center;
}
