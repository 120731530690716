@import '../../styles/typography.module.scss';
@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  justify-content: flex-start;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0px;
  @media screen and (min-width: $breakpoint-md) {
    height: auto;
    max-height: 800px;
    width: auto;
    max-width: 100%;
    margin: var(--spacing-sm);
    border-radius: 8px;
  }
}
.contentFullScreen {
  justify-content: flex-start;
  margin: 0;
  max-width: 750px;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  @media screen and (min-width: $breakpoint-md) {
    height: auto;
    max-height: 800px;
    width: auto;
    max-width: 100%;
    margin: var(--spacing-sm);
    border-radius: 8px;
  }
}
.successContainer {
  width: 280px;
  height: 288px;
  .title {
    margin: 0;
  }
  .ticketNumber {
    color: var(--color-white);
    margin-bottom: 15px;
  }
  .ticketIdCont {
    span {
      color: var(--color-white);
    }
  }
}
.title {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  span {
    color: #aaa8a7;
    font-weight: normal;
    font-size: 14px;
  }
  .supportLink {
    font-weight: bold;
    text-decoration: underline;
  }
  div {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  p {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.alignedButton {
  width: 238px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contactButton {
  margin-bottom: 12px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.fileIcon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  color: #aaa8a7;
}

.supportIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.fileIconButton {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.cancel {
  margin-top: 20px;
  justify-content: center;
}

.okButton {
  margin-top: 20px;
  width: 132px;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 32px;
  margin-top: auto;
}
