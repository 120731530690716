@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.emptyImage {
  width: 304px;
  margin-bottom: 32px;
}

.emptyText {
  margin-bottom: 24px;
  color: var(--color-white);
  text-align: center;
}

.emptyButton {
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    filter: brightness(0) saturate(100%) invert(3%) sepia(12%) saturate(1682%)
      hue-rotate(332deg) brightness(101%) contrast(101%);
  }
}
