@import '../../styles/variables.module.scss';

.root {
  position: absolute;
  top: var(--top-bar-height);
  left: 0;
  z-index: 1000;
  width: 72px;
  height: calc(100% - var(--top-bar-height));
  overflow: hidden;
  background: var(--color-black);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  transition: left 0.3s ease-in-out;
  overflow-y: auto;

  @media screen and (min-width: $breakpoint-md) {
    position: static;
    z-index: unset;
    height: 100%;
  }
}

.hidden {
  left: -100%;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  a {
    text-decoration: none;
  }
}

.logo {
  width: 48px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-grey-600);
  margin-bottom: 12px;
}

.community {
  background: var(--color-grey-500);
  outline: none;
  cursor: pointer;
  border: 0;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  position: relative;

  img {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }

  p {
    color: var(--color-white);
  }
}

.activeCommunity {
  &:before {
    content: ' ';
    position: absolute;
    top: 8px;
    left: -15px;
    width: 8px;
    height: 32px;
    border-radius: 4px;
    background: var(--color-yellow);
  }
  &.noImage {
    background: var(--color-yellow);
  }
}

.addCommunity {
  background: transparent;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--color-yellow);
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  .plus {
    width: 32px;
    height: 32px;
    color: var(--color-white);
  }
}
