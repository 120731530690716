@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  height: 458px;
  justify-content: flex-start;
  align-items: center;
}

.title {
  text-align: center;
  margin-bottom: 18px;
}

.gridContainer {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-items: center;

  row-gap: 0.25em;
  width: 100%;

  overflow: auto;
  overscroll-behavior: contain;

  & > *:nth-child(2n) {
    background-color: var(--color-grey-600);
  }
}

.gridItem {
  width: 100%;
  display: flex;
  align-items: center;
  & > * {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.gridHeader {
  position: sticky;
  top: 0;
  background-color: var(--color-grey-500);
  font-weight: 700;
  color: var(--color-white);
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding-top: 32px;
  margin-top: auto;
}
.cancel {
  width: 100%;
}
