@import '../../../styles/variables.module.scss';

.titleRow {
  display: flex;
  width: 100%;
  flex-flow: row;
  align-items: center;
  gap: 1.75rem;
}

.titleRowFooter {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.titleLabel {
  white-space: nowrap;
}

.titleRowBody {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sectionPadding {
  padding: 10px 0;
}

.row {
  width: 100%;
  max-width: 514px;
  flex-flow: row wrap;
}

.text {
  color: var(--color-grey);
  margin-bottom: 4px;
}

.badge {
  //margin-left: 1.5rem;
}

.info {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.infoTextColor {
  color: var(--color-grey);
}
