@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-sm);

  @media screen and (min-width: $breakpoint-md) {
    padding: var(--spacing-md) var(--spacing-lg);
  }
}

.contentEmpty {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: var(--spacing-sm);
  background-image: url('../../images/yellowPlanet.png'),
    url('../../images/bluePlanet.png'), url('../../images/Flying-WhiteBG.png'),
    url('../../images/emptyTPCsList.svg');
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 284px 160px, 248px 140px, 163px 200px, 110vw auto;
  background-position: calc(100% + 120px) 100%, calc(100% + 120px) 20%,
    -45px 105%, 0px 100%;
  @media screen and (min-width: $breakpoint-md) {
    padding: var(--spacing-md) var(--spacing-lg);
    background-size: 655px 407px, 496px 279px, 395px 483px, 100vw 100vh;
    background-position: calc(100% + 230px) 50%, 90% -96px, 0 100%, 0px 0px;
  }
}
