@import '../../styles/variables.module.scss';

.msgContainer {
  display: flex;
  width: 100%;
  background-color: var(--bg-color);
  color: var(--color-black);
  padding: 10px;
  justify-content: center;
}
.msgHeaderLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    font-size: 12px;
    font-weight: 700;
  }
  @media screen and (min-width: $breakpoint-md) {
    p {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.msgHeaderRight {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.rocketIcon {
  width: 30px;
  height: 30px;
}
.closeButton {
  width: 26px;
  height: 26px;
  padding: 0;

  .xIcon {
    width: 26px;
    height: 26px;
    color: var(--color-black);
  }
}

.vwrap,
.vitem {
  height: 30px;
  line-height: 30px;
}
.vwrap {
  overflow: hidden;
}
.vitem {
  display: flex;
  align-items: center;
  text-align: center;
}
.vmove {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

//  @keyframes tickerv {
//    0% {
//      bottom: 0;
//    }
//    50% {
//      bottom: 30px;
//    }
//    100% {
//      bottom: 0;
//    }
//  }
//  .vmove {
//    animation-name: tickerv;
//    animation-duration: 7s;
//    animation-iteration-count: infinite;
//    animation-timing-function: cubic-bezier(1, 0, 0.5, 0);
//  }
//  .vmove:hover {
//    animation-play-state: paused;
//  }
