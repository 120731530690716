@import '../../styles/variables.module.scss';

.root {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  gap: 25px;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: var(--top-bar-height);
  width: 100%;
  background-color: var(--color-black-new);
  z-index: 1000;
}

.burgerMenuButton {
  line-height: 0;
}

.burgerMenu {
  margin: 0;
  height: 16px;
  width: 16px;
  color: var(--color-white);

  @media screen and (min-width: $breakpoint-sm) {
    margin: 0 0.5rem;
  }
}

.logo {
  height: 38px;
  width: 38px;
  object-fit: contain;
}

.activeCommunityContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
}

.communityName {
  color: var(--color-white);
}

.communitySubName {
  width: 100%;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  .subtitle {
    text-transform: capitalize;
  }
}

.placeholderInput {
  width: 170px;
  height: 22px;
  background: var(--color-grey-500);
  border-radius: 4px;
  margin: 0 0px 4px;
}

.ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
