.main {
  color: var(--color-white);
  position: relative;
  padding-left: 20px;

  &:before {
    content: ' ';
    background: var(--custom-color);
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 1px;
  }
}

.lowContrast {
  color: var(--color-white);
  &:before {
    outline: 1px solid rgba(211, 211, 211, 0.25);
  }
}
