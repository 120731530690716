@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.container {
  display: flex;
  //width: fit-content;
  width: 500px;
  padding: 24px;
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

.text {
  color: var(--color-white);
  overflow-wrap: anywhere;
}

.priceText {
  color: var(--color-white);
}

.priceTextError {
  color: var(--color-error);
}

.priceValue {
  color: var(--color-yellow);
  width: 100%;
  font-size: 16px;
  text-align: right;
}

.infoText {
  width: 100%;
  color: var(--color-white);
  text-align: center;
  padding: 14px 0;
  margin-bottom: 25px;
}

.errorText {
  width: 100%;
  color: var(--color-error);
  text-align: center;
  margin-bottom: 25px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: 16px;
}

.contentRow {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.buttonsRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-flow: row nowrap;
  margin-top: auto;
}

.buttonFixWidth {
  width: 130px;
}

.uninstallText {
  white-space: inherit;
  max-width: 400px;
  margin-bottom: 35px;
  text-align: center;
}

.statusText {
  color: var(--color-green);
}

.metaDataContainer {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
  align-items: flex-start;

  a {
    text-decoration: underline;
  }

  p {
    overflow-wrap: anywhere;
  }
}

.metadataKey {
  color: var(--color-grey-200);
}

.secondaryHeader {
  margin-top: 20px;
}
