@import '../../styles/variables.module.scss';

.walletContainer {
  background-color: var(--color-grey-500);
  border-radius: 8px;
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  gap: 20px;

  @media screen and (min-width: $breakpoint-md) {
    flex-direction: row;
    align-items: center;
    padding: 18px 20px;
    gap: 15px;
    margin: 28px 0 0 0;
  }
}

.lineContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  line-height: 16px;
  gap: 5px;

  @media screen and (min-width: $breakpoint-md) {
    margin-left: 8px;
  }
  p {
    font-size: 14px;
    color: var(--color-white);
  }
}

.icon {
  width: 24px;
  height: 24px;
}
