.toastContainer {
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
}

.ToastViewport {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 390px;
  max-width: 90vw;
  margin: 0px;
  list-style: none;
  outline: none;
}

.toastParent {
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  display: flex;
  align-items: center;
}

.toastParent[data-state='open'] {
  -webkit-animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.toastParent[data-state='closed'] {
  -webkit-animation: hide 100ms ease-in forwards;
  animation: hide 100ms ease-in forwards;
}

.toastDescription {
  margin: 0;
  color: var(--color-white);
  display: flex;
  align-items: center;
  width: 100%;
}

.topRight {
  top: 15px;
  right: 15px;
}

.topLeft {
  top: 15px;
  left: 15px;
}

.bottomLeft {
  bottom: 15px;
  left: 15px;
}

.bottomRight {
  bottom: 15px;
  right: 15px;
}

.closeBtn {
  font-size: 24px;
  opacity: 0.7;
  color: var(--color-white);
  &:hover {
    opacity: 1;
  }
}

.infoIcon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  color: var(--color-blue);
  margin-right: 5px;
}

.successIcon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  color: var(--color-green);
  margin-right: 5px;
}

.errorIcon {
  width: 18px;
  height: 18px;
  color: var(--color-red);
  margin-right: 5px;
}

.warningIcon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  color: var(--color-yellow);
  margin-right: 5px;
}

@-webkit-keyframes slideIn {
  from {
    transform: translateX(calc(100% + 25px));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + 25px));
  }
  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
