@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.headerRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.invalidContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 20px 0;
  padding-bottom: 20px;

  border-bottom: 2px solid grey;

  & > .invalidCard {
    width: 240px;
    min-width: 240px;
    margin-right: 20px;
    border: 1px solid red;
  }
}
