@import '../../styles/variables.module.scss';

.root {
  border: none;
  outline: none;
  border-radius: 8px;

  cursor: pointer;

  transition: background-color 0.3s ease-in-out;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;

  align-self: center;
  text-decoration: none;
}

.icon {
  display: flex;
  align-items: center;
  justify-self: center;
}

.leftIcon {
  margin-right: 4px;
}

.rightIcon {
  margin-left: 4px;
}

.arrow {
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.root.rounded {
  border-radius: 16px;
}

.root.full {
  width: 100%;
}

.root.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.outline {
  --box-shadow-color: var(--color-primary);
  box-shadow: 0 0 0 1px var(--color-primary);
}

.outline:focus,
.outline:hover {
  box-shadow: 0 0 0 2px var(--color-primary);
}

.outline:active {
  box-shadow: 0 0 0 2px var(--color-primary);
}

.small {
  height: 32px;
  padding: 0 24px;
}

.medium {
  height: 40px;
  padding: 0 24px;
}

.withIcon {
  //
}

.large {
  height: 48px;
  padding: 0 24px;
}

.primary {
  background-color: var(--color-yellow);
  color: var(--color-black);
  border: 1px solid var(--color-yellow);
}

.primary:focus,
.primary:hover {
  background-color: var(--color-yellow-300);
}

.primary:active {
  background-color: var(--color-yellow-400);
}

.secondary {
  background-color: transparent;
  color: var(--color-white);
  border: 1px solid var(--color-yellow);

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: rgba(253, 163, 64, 0.2);
    }

    &:active {
      background-color: rgba(252, 133, 3, 0.2);
      border: 1px solid var(--color-yellow-400);
    }
  }
}

// .secondary:focus,
// .secondary:hover {
//   background-color: rgba(253, 163, 64, 0.2);
// }

// .secondary:active {
//   background-color: rgba(252, 133, 3, 0.2);
//   border: 1px solid var(--color-yellow-400);
// }

.secondary-light {
  background-color: transparent;
  color: var(--color-white);
  border: 1px solid var(--color-white);
}

.secondary-light:focus,
.secondary-light:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.secondary-light:active {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid var(--color-white);
}

.secondary-dark {
  background-color: transparent;
  color: var(--color-black);
  border: 1px solid var(--color-black);
}

.secondary-dark:focus,
.secondary-dark:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.secondary-dark:active {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid var(--color-black);
}

.empty {
  background-color: transparent;
  color: var(--color-white);
  height: auto;
  padding: 0;
}

.danger {
  background-color: var(--color-error);
  color: var(--color-white);
}

.danger-secondary {
  color: var(--color-white);
  border: 1px solid var(--color-error);
}

.danger .danger-secondary:focus,
.danger .danger-secondary:hover {
  box-shadow: inset 1px -2px 1px rgba(255, 255, 255, 0.1),
    inset -2px 3px 10px rgba(255, 255, 255, 0.7);
}

.danger .danger-secondary:active {
  box-shadow: inset 1px -2px 1px rgba(255, 255, 255, 0.1),
    inset -2px 3px 10px rgba(255, 255, 255, 0.7);
}

.danger-outline {
  background-color: transparent;
  color: var(--color-white);
  border: 1px solid var(--color-error);
}

.danger-outline:focus,
.danger-outline:hover {
  background-color: rgba(195, 30, 51, 0.2);
}

.danger-outline:active {
  background-color: rgba(195, 30, 51, 0.2);
  border: 1px solid var(--color-error);
}
