@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.filterButton {
  color: var(--color-white);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:focus-visible {
    outline: 1px solid var(--color-grey-300);
    border-radius: 4px;
  }
  &[data-state='open'] {
    svg {
      transform: rotate(180deg);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    order: unset;
  }
}

.sortDropdownWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 32px;
  user-select: none;
  position: relative;

  p {
    user-select: none;
    color: var(--color-grey-300);
  }

  span {
    color: var(--color-white);
  }
}

.dropdown_button {
  padding: 8px 12px;
  display: flex;
  cursor: pointer;
  color: var(--color-white);

  &[data-highlighted],
  &:hover {
    background: var(--color-grey-500);
  }
}

.dropdown_button:disabled {
  color: var(--color-grey-600);
}

.dropdown_content {
  background: var(--color-grey-600);
  padding: 8px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 999;
}

.dropdown_trigger {
  width: max-content;
  height: 36px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-white);
}

.dropdown_outlined {
  background: var(--color-black);
  border: 1px solid var(--color-yellow-400);
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  margin: 0;

  p {
    display: inline;
    color: var(--color-grey-300);
  }
}
