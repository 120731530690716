@import '../../styles/variables.module.scss';

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  background-image: url('../../images/robotNotFound.png');
  background-repeat: no-repeat;
  background-size: auto 451px;
  background-position: calc(100% + 150px) calc(100% + 180px);
  @media screen and (min-width: $breakpoint-md) {
    background-position: 100% 50%;
  }

  h2 {
    text-align: center;
    color: var(--color-white);
    margin-bottom: 24px;
  }

  button {
    width: 160px;
  }
}
