.container {
  display: inline-block;
  font-size: 10px;
  line-height: 1.6;
  font-weight: 600;
  vertical-align: middle;
  padding: 0 5px;
  color: black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.large {
  line-height: 2.2;
  padding: 0 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0.65;
  }
}

.flicker {
  animation: fadeIn 0.5s infinite alternate;
}
