.headerFull {
  padding: 12px 40px;
  width: 100%;
}

.headerNormal {
  max-width: 256px;
}

.search {
  width: 100%;
  position: relative;
  height: 32px;

  input {
    width: 100%;
    height: 100%;
    padding-left: 36px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 8px;
    background: var(--color-grey-600);
    border: 1px solid var(--color-grey-600);
    &::placeholder {
      color: var(--color-grey-200);
    }
  }
  .searchIcon {
    color: var(--color-grey-200);
    position: absolute;
    left: 12px;
    top: 8px;
    width: 16px;
    height: 16px;
  }
}

.fullWidth {
  width: 100%;
}
