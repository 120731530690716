@import '../../../../styles/variables.module.scss';
@import '../../../../styles/common.module.scss';

.listContainer {
  width: 100%;
  height: 240px;
  overflow: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.item {
  display: flex;
  margin: 14px 0 14px 0;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--color-grey-500);

  label {
    font-family: var(--font-secondary);
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }

  button {
    font-family: var(--font-secondary);
    border: 0;
    border-radius: 3px;
    height: 20px;
    width: 81px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
  }
}

.installButton {
}

.installedButton {
  background-color: #686868;
}

.heading {
  color: var(--color-black);
  font-family: var(--font-secondary);
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 20px;
  word-wrap: break-word;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
}

.contextText {
  color: #7d8087;
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  width: 100%;
  word-wrap: break-word;
}

.heading2 {
  color: var(--color-black);
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 20px;
  word-wrap: break-word;
}

.contextText2 {
  color: #7d8087;
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: 100%;
  word-wrap: break-word;

  b {
    color: var(--color-black);
    font-weight: 1000;
  }
}

.tgrButton {
  margin-top: 20px;
  width: 100%;
}

.roleGuide {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 7px;
  width: 150px;
  height: 109px;
}
