@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  max-height: auto;
  max-width: 750px;
  justify-content: flex-start;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  @media screen and (min-width: $breakpoint-md) {
    width: calc(100vw - calc(var(--spacing-md) * 2));
    margin: var(--spacing-sm);
    height: unset;
    border-radius: 8px;
  }
}

.title {
  text-align: center;
}

.traitsWrapper {
  max-height: 450px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  position: relative;
  margin-top: 20px;
}

.traitsHeader {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  &::after {
    content: '';
    display: block;
    width: 35px;
  }
}

.traitsHeaderTitle {
  width: calc(50% - 10px);
  color: var(--color-white);
  &:last-child {
    margin-right: 15px;
  }
}

.metadataRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  padding: 10px;
  border-radius: 8px;
  background: #393837;

  @media screen and (min-width: ($breakpoint-sm)) {
    padding: 0 15px 0 0;
    background: none;
    border: none;
  }
}

.metadataContainer {
  display: flex;
  flex-flow: column wrap;
  flex: 1;
  gap: var(--spacing-sm);
  margin-right: var(--spacing-sm);

  @media screen and (min-width: ($breakpoint-sm)) {
    flex-flow: row nowrap;
  }
}

.metadataList {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overscroll-behavior: contain;

  @media screen and (min-width: $breakpoint-md) {
    scrollbar-width: thin;
    scrollbar-color: var(--color-grey-600) rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
      width: 6px;
      background-color: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: var(--color-grey-600);
    }
  }
}

.metadataInput {
  position: relative;

  @media screen and (min-width: ($breakpoint-sm)) {
    width: calc(50% - 10px);
  }
}

.addMetadataButton {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  align-self: self-start;
  margin-top: 20px;
  height: 40px;
  min-height: 40px;

  .plus {
    width: 16px;
    height: 16px;
    margin-right: 3px;
    color: var(--color-yellow);
  }
}

.separatorRow {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  p {
    color: var(--color-grey-300);
    margin-right: 6px;
    margin-left: 11px;
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 11px;
  }
}

.separatorLine {
  width: 195px;
  height: 2px;
  background: var(--color-grey-600);
}

.inputLabel {
  color: var(--color-white);
}

.caption {
  margin-top: 4px;
  color: var(--color-grey-300);
  font-size: 11px;
}

.row {
  justify-content: center;
  gap: 20px;
  padding-top: 32px;
  margin-top: auto;
}

.cancel {
  width: 220px;
}

.submit {
  width: 220px;
}

.traitsIdWrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  margin-top: 20px;
}
.traitsBlur {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(38, 38, 38, 0.8);
}
.metadataBodyButton {
  width: 24px;
  height: 24px;
  padding: 0;

  .close {
    color: var(--color-white);
    width: 24px;
    height: 24px;
  }
}

.infoMsg {
  margin-bottom: 30px;
  text-align: left;
  width: 100%;
  p {
    color: var(--color-white);
  }

  span {
    color: #7b7977;
    font-weight: normal;
  }
}

.switchContainer {
  align-self: flex-end;
  display: flex;
  min-width: 100px;
  border: 1px solid var(--color-yellow);
  border-radius: 8px;

  & > button {
    color: white;
    padding: 10px;
    border-radius: 8px;
    flex: 1 0 50%;
    transition: background-color ease-in 150ms;
    &[data-active='true'] {
      background-color: rgba(253, 163, 64, 0.2);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    margin-right: 18px;
  }
}
