.iconLogo {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  margin-right: 7px;
}

.iconLogoT {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  margin-right: 7px;
}
