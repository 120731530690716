@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-sm);
  //box-sizing: border-box;
  //margin: 0;
  //@media screen and (min-width: $breakpoint-md) {
  //  padding: var(--spacing-md) var(--spacing-lg);
  //}
}

.contentEmpty {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: var(--spacing-sm);
  background-image: url('../../images/yellowPlanet.png'),
    url('../../images/bluePlanet.png'), url('../../images/Flying-WhiteBG.png'),
    url('../../images/emptyTPCsList.svg');
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 284px 160px, 248px 140px, 163px 200px, 110vw auto;
  background-position: calc(100% + 120px) 100%, calc(100% + 120px) 20%,
    -45px 105%, 0px 100%;
  @media screen and (min-width: $breakpoint-md) {
    padding: var(--spacing-md) var(--spacing-lg);
    background-size: 655px 407px, 496px 279px, 395px 483px, 100vw 100vh;
    background-position: calc(100% + 230px) 50%, 90% -96px, 0 100%, 0px 0px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  flex-wrap: wrap;
  row-gap: 4px;
  overflow-y: auto;
  height: 100vh; /* Adjust height as needed */
}

.container::-webkit-scrollbar {
  display: none;
}

.groupCard {
  width: 280px; /* Fixed width for each card */
  margin-top: 24px;
  margin-right: 16px;
  background-color: #313131;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px;
  text-align: center;
}

.andButton {
  border: 1px dashed #858383;
  border-radius: 8px;
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: row;
}

.orButton {
  border: 1px dashed #858383;
  border-radius: 8px;
  width: 100%;
  height: 160px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 600px) {
  .container {
    flex-direction: row;
  }
}
