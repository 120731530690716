@import '../../styles/variables.module.scss';
@import '../../styles/common.module.scss';

.content {
  padding: 32px 40px;
  width: 100%;
  height: calc(100% - 56px);
}

iframe {
  display: block;
  border: none;
  height: 100%;
  width: calc(100% - 56px);
  .content {
    background: #0b0604;
  }
}
.primerContainer {
  width: 100%;
  height: 100%;
  padding: 36px;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column nowrap;
}

.title {
  text-align: center;
  margin-bottom: 15px;
}

.text {
  color: var(--color-white);
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.btn {
  margin-bottom: 16px;
  min-width: 288px;

  img {
    margin-right: 12px;
  }

  svg {
    margin-right: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.backButton {
  padding: 0;
  margin: 8px auto 28px 0;
  height: 24px;
  color: var(--color-yellow);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .arrow {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }
}
