@import '../../styles/typography.module.scss';
@import '../../styles/variables.module.scss';

.content {
  justify-content: flex-start;
  margin: 0;
  max-width: 750px;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  @media screen and (min-width: $breakpoint-md) {
    height: auto;
    max-height: 750px;
    width: auto;
    max-width: 100%;
    margin: var(--spacing-sm);
    border-radius: 8px;
  }
}
.title {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  span {
    color: #aaa8a7;
    font-weight: normal;
    font-size: 14px;
  }
  .supportLink {
    font-weight: bold;
    text-decoration: underline;
  }
  div {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  p {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
.formRow {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: left;
  justify-content: flex-start;
  @media screen and (min-width: $breakpoint-md) {
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 20px;
  }
  gap: var(--spacing-sm);
}
.selectError {
  div[class*='control'] {
    border: 2px solid var(--color-error) !important;
  }
}

.infoTopAlt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  margin-bottom: 6px;
  .infoIcon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    pointer-events: none;
  }
  .infoText {
    color: var(--color-grey-200);
    font-weight: 600;
    font-size: 12px;
  }
  span {
    color: var(--color-grey-500);
    font-size: 12px;
    margin-left: 8px;
  }
}

.generalInput {
  width: 100%;
}
.buttonsRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  gap: 20px;
  padding-top: 32px;
  margin-top: auto;
}
.fileIcon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  color: #aaa8a7;
}

.emptyState {
  text-align: center;
  margin-top: 2rem;
  color: var(--color-grey-200);
}
