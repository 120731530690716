@import '../../styles/variables.module.scss';

.root {
  width: 100%;
}

.label {
  color: var(--color-grey-200);
  margin-bottom: 8px;
}

.labelContainer {
  display: flex;
  span {
    color: var(--color-grey-500);
    font-size: 12px;
    margin-left: 8px;
    margin-top: 3px;
  }
}

.error {
  color: var(--color-error);
  display: flex;
  align-items: center;
  margin: 8px 0 0;
}

.errorIcon {
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.input {
  flex: 1;
  height: 40px;
  background-color: var(--color-black);
  color: var(--color-white);

  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid var(--color-yellow-400);
  outline: none;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.input.textarea {
  height: 111px;
  padding: 16px;
  resize: none;
}

.input::placeholder {
  color: var(--color-grey);
}

.input:disabled {
  color: var(--color-grey-500);
}

.disabled {
  box-shadow: 0 0 0 1px var(--color-grey-500) inset !important;
}

.action {
  margin: 0 16px;
}

.inputContainer {
  display: flex;
  align-items: center;

  background-color: transparent;
  border-radius: 8px;
}

.inputWrap {
  display: flex;
  align-items: center;

  flex: 1;
}

.withError {
  outline: 2px solid var(--color-error);
  outline-offset: -1px;
}

.withWarning {
  outline: 2px solid var(--color-yellow);
  outline-offset: -1px;
}

.inlineErrorMsgInfo {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-left: 8px;
  margin-right: 8px;
  color: var(--color-error);
}

.pressed.outline.focus {
  box-shadow: 0 0 0 2px var(--color-secondary-2) inset;
}

.outline {
  box-shadow: 0 0 0 1px var(--color-primary) inset;
  transition: box-shadow 0.3s ease-in-out;
}

.outline.focus,
.outline:hover {
  box-shadow: 0 0 0 2px var(--color-primary) inset;
}

.leftIcon,
.rightIcon {
  width: 32px;
  height: 32px;
  display: block;
}

.leftIcon {
  margin-right: 4px;
  margin-left: 8px;
}

.rightIcon {
  margin-left: 4px;
  margin-right: 8px;
}

.input.withLeftIcon {
  padding-left: 0;
}

input.withRightIcon {
  padding-right: 0;
}
