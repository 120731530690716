@import '../../styles/variables.module.scss';

.root {
  width: 100%;
  height: 100%;
  background: var(--color-black);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row nowrap;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-md) {
    width: calc(100vw - 72px);
  }
}

.main {
  position: absolute;
  top: var(--top-bar-height);
  left: 72px;
  z-index: 500;
  width: calc(90% - 72px);
  max-width: 240px;
  height: calc(100% - var(--top-bar-height));
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
  background: var(--color-grey-600);
  transition: left 0.3s ease-in-out;
  overflow-y: auto;

  @media screen and (min-width: $breakpoint-md) {
    position: static;
    z-index: unset;
    height: 100vh;
  }
}

.hidden {
  left: calc(-100% - 72px);
}

.content {
  width: 100%;
  height: calc(100vh - var(--top-bar-height));
  overflow-x: hidden;
  overflow-y: auto;

  @media screen and (min-width: $breakpoint-md) {
    position: static;
    width: calc(100% - 240px);
    height: 100vh;
  }
}
